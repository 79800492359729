import React, { useEffect, useRef } from "react"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import NewExitIntentImage from "../../images/new-images/2023_Quickbase_Empower_Quandary.png"

const SystemIntegConsulModal = ({ onMobile }) => {
  const modalContainer = useRef(null)

  // useEffect(() => {
  //   let timeout
  //   if (onMobile) {
  //     timeout = setTimeout(() => {
  //       const modalContainerRef = modalContainer.current
  //       if (modalContainerRef) {
  //         const finishSurvey = sessionStorage.getItem("SystemInteg")
  //         if (!finishSurvey) {
  //           modalContainerRef.style.display = "block"
  //           sessionStorage.setItem("SystemInteg", true)
  //         }
  //       }
  //     }, 8000)
  //   } else {
  //     timeout = setTimeout(() => {
  //       document.addEventListener("mouseleave", e => {
  //         const modalContainerRef = modalContainer.current
  //         if (!e.toElement && !e.relatedTarget && modalContainerRef) {
  //           const finishSurvey = sessionStorage.getItem("SystemInteg")
  //           if (!finishSurvey) {
  //             modalContainerRef.style.display = "block"
  //             sessionStorage.setItem("SystemInteg", true)
  //           }
  //         }
  //       })
  //     }, 6000)
  //   }

  //   return () => clearTimeout(timeout)
  // }, [onMobile])

  const onModalClose = () => {
    const modalContainerRef = modalContainer.current
    modalContainerRef.style.display = "none"
  }

  useEffect(() => {
    // window.onunload = function() {
    //   sessionStorage.removeItem("SystemInteg")
    // }
    document.addEventListener("DOMContentLoaded", () => {
      window.onpageshow = function(event) {
        if (event.persisted) {
          sessionStorage.removeItem("SystemInteg")
        }
      }
    })
  }, [])

  return (
    <>
      <div
        ref={modalContainer}
        className="exit-intent-popup vh-100 w-100 position-fixed"
        style={{
          display: "none",
          zIndex: 5,
          top: 0,
        }}
      >
        <div
          className="vh-100 w-100"
          style={{
            background: "rgba(0, 0, 0, 0.7)",
            top: 0,
            left: 0,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div id="signupForm" className="px-4 pb-4">
            <div
              id="clost-btn"
              onClick={onModalClose}
              className="close-btn exit-intent-close-btn"
              style={{ top: "-20px", right: "-20px" }}
            >
              x
            </div>

            {/* NEWLY ADDED EXIT INTENT CONTENT */}
            <LazyLoadComponent>
              <div className="img-wrap">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.quickbase.com/empower?utm_source=quandary&utm_medium=email&utm_campaign=empower23"
                >
                  <img
                    src={NewExitIntentImage}
                    alt="exit intent image"
                    className="img-fluid w-100"
                  />
                </a>
              </div>
            </LazyLoadComponent>
          </div>
        </div>
      </div>
    </>
  )
}

export default SystemIntegConsulModal
